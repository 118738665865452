import React from "react";
import { TermsContainer } from "./styles";

const Terms = () =>
{

    return (
        <TermsContainer>
            <div id="container">
                <h1>Terms & Conditions</h1>
                <h2>KLINIK EVERYWHERE TECHNOLOGIES PRIVATE LIMITED Subscription </h2> 
                <p>Terms of Service
This Subscription Terms of Use (“Agreement”) is a legal contract between the You (“Subscriber”) and Klinik Everywhere Technologies Private Limited, a limited liability partnership having its registered office at 121, Kabali Nagar 8th cross street Aadhanur Village, Guduvancherry TN 603202 IN (hereinafter referred to as “KlinikEverywhere” which expression shall mean and include its affiliates, successors and permitted assigns).

This Agreement is an electronic record in terms of Information Technology Act, 2000 and generated by a computer system and does not require any physical or digital signatures. This Agreement is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the Services.

WHEREAS
KlinikEverywhere is the developer and the proprietor of the Software (defined hereinafter below) that facilitates individuals/establishments looking for web-based outpatient clinical management, creation and generation of electronic medical records, and other Services.

The Subscriber is desirous of subscribing to the Software for the Purpose, and KlinikEverywhere has agreed to license the Software to the Subscriber.

NOW THEREFORE, in consideration of the Purpose and the mutual covenants and agreements contained herein, the Subscriber and KlinikEverywhere enter into this Agreement that govern the terms and conditions of use of the Software for the Purpose by the Subscriber.

Definitions

“Privacy Policy” shall mean the privacy policy governing the use of the Software which may be accessible at Privacy policy.
“Services” shall mean the services enumerated in Annexure I of this Agreement pursuant to subscription of the Software.
“Software” shall mean the SpotCare and TeleCare software through which the Services are provided to the Subscriber.
“Terms of Use” shall mean the terms and conditions of use of the platform which shall be applicable in addition to this Agreement. The same may be accessible at Terms of use.
Subscription by the Subscriber

Subject to all limitations and restrictions contained herein, KlinikEverywhere hereby grants to the Subscriber a non-exclusive, revocable, limited, and non-transferable license to access and use the Software in accordance with the terms and conditions of this Agreement, the Terms of Use, and the Privacy Policy for the purposes of rendering the Services.
The Subscriber shall have a limited right and license to use the Software, and the Services solely for its internal purposes.
The Subscriber acknowledges that the Software is provided ‘as is, and therefore shall not include any customizations. KlinikEverywhere may upon request and its sole discretion provide customizations to the Software at such additional costs as determined by KlinikEverywhere.
Registration Fees and Payment

Notwithstanding the convenience fees described in the Terms of Use, the Subscriber shall pay to KlinikEverywhere a fee as mutually determined between the Subscriber and the KlinikEverywhere for activation of the Software (“Registration Fees”). The Software shall be activated promptly upon receipt of Subscription Fee by KlinikEverywhere.
The Registration Fees shall be inclusive of all applicable taxes, and KlinikEverywhere shall be entitled to raise invoices at its sole discretion. KlinikEverywhere shall be entitled to raise separate invoices for any upgrades and customizations at its sole discretion.
The Registration Fees and other fees paid by the Subscriber shall not be refunded in any event.
Representations and Warranties

By the Subscriber: The Subscriber represents and warrants that the (a) Subscriber is 18 years of age or older and has the right, authority and capacity under the applicable law to use the Software and enter into this Agreement; (b) Subscriber will not transfer (including by way of sublicense, lease, assignment or other transfer, including by operation of law) their login and Account details or right to use the Software to any third party; (c) information given to KlinikEverywhere or uploaded on the Software by the Subscriber shall always be true, accurate, correct, complete and up to date, to the best of the Subscriber’s knowledge and that the Subscriber is the owner of the data disclosed and the Subscriber has the necessary rights (under applicable laws) from the end-user to upload such information on the Software, including but not limited to end-user health records and that such right from the end-user shall include an explicit right for KlinikEverywhere to reflect and map such content with an account of such user as may be created by such user through the KlinikEverywhere website and for KlinikEverywhere to further store and use content for the purposes of KlinikEverywhere’s business and for providing such other services in India and outside India, as may be applicable ; (d) Subscriber shall not engage in any activity that interferes with or disrupts the Software or the servers and networks which are connected to the Software
By KlinikEverywhere. The Software provided by KlinikEverywhere is provided on an “as is”, “as available” basis and KlinikEverywhere makes no express or implied representations or warranties about its Services and/ or the Software or of merchantability or fitness for a particular purpose or use or non-infringement. KlinikEverywhere does not authorize anyone to make a warranty on KlinikEverywhere’s behalf and the Subscriber may not rely on any statement of warranty as a warranty by KlinikEverywhere.
Term and Termination.

This Agreement shall remain in full force and effect unless terminated by the parties in accordance with the provisions mentioned hereunder.
Termination for Convenience: KlinikEverywhere shall have a right to terminate this Agreement for convenience by providing thirty (30) days’ prior written notice to the Subscriber.
Termination for Cause. Each party reserves the right to terminate this Agreement if the other party breaches a material obligation under this Agreement or the Terms of Use, Privacy Policy, and other ancillary applicable documents or applicable and such breach remains uncured for a period of thirty (30) days from the date of notification of the non-breaching party.
Effect of termination On termination of this Agreement due to the reasons mentioned in Clause 6.2 and 6.3 herein above, KlinikEverywhere reserves the right to immediately terminate the subscription of the Software to the Subscriber. Nothing contained in this Agreement shall restrict KlinikEverywhere’s use of the data or right to publish information made available by the Subscriber in the public domain through the subscription services or any other platform managed by KlinikEverywhere after the termination or expiry of this Agreement. After a 30 (thirty) day period from the date of termination of the Agreement, KlinikEverywhere shall have no obligation to maintain or provide any Subscriber’s data and shall thereafter, unless legally prohibited, delete all Subscriber’s data in its systems or otherwise in its possession or under its control. In cases where the Subscriber terminates the subscription voluntarily, it shall be the sole responsibility of the Subscriber to make a copy of their data before terminating the subscription. End-Users data shall not be available after termination of subscription in such cases.
Intellectual Property
KlinikEverywhere retains all right, title and interest in and to and ownership of all the Software including customisations if any, to all updates and all modifications and the intellectual property rights thereto. KlinikEverywhere does not transfer either the title or the intellectual property rights of the Software and its Services to the Subscriber(s).

Confidentiality
The parties to this Agreement undertake to retain in confidence all information disclosed to the other party in relation to this Agreement that the disclosing party has designated as being confidential in writing or if disclosed orally, or if, designated as confidential at the time of such disclosure and reduced to writing conspicuously marked as confidential and sent to such other party within thirty (30) business days thereof (“Confidential Information“). The terms and conditions of this Agreement including its Annexure shall be considered Confidential Information.

“Confidential Information” shall not include information that: (a) is or becomes generally known or available by publication, commercial use or otherwise through no fault of the receiving party; (b) the receiving party can demonstrate to have had rightfully in its possession and without restriction, prior to disclosure hereunder; (c) is independently developed by the receiving party without use of the disclosing part’s Confidential Information, as can be shown by tile written records of the receiving party; (d) is lawfully obtained from a third party who has the right to make such disclosure; or (e) is released for publication by the disclosing party in writing. A receiving party also may disclose disclosing party’s Confidential Information to the extent required by a court or other governmental authority, provided that the receiving party promptly notifies the disclosing party of the disclosure requirement and cooperates with the disclosing party (at the latter’s expense and at its request) to resist or limit the disclosure.

Indemnity
The Subscriber shall defend, indemnify, and hold harmless KlinikEverywhere, its corporate affiliates, or any of its or their respective directors, officers, owners, employees, agents, successors, and permitted assigns from and against any and all third-party claims, suits, proceedings, costs, and expenses (including, without limitation, attorneys’ fees) arising from or related to the Customer’s (a) breach of the confidentiality obligations, breach of intellectual property provisions and breach of representation and warranties provisions herein; (b) violation of any applicable law; and (c) wilful misconduct and gross negligence.

Limitation of Liability
IN NO EVENT SHALL KLINIKEVERYWHERE BE LIABLE TO THE SUBSCRIBER FOR ANY LOST OR CORRUPTED DATA, DOWNTIME, LOST PROFITS, BUSINESS INTERRUPTION, REPLACEMENT SERVICE OR OTHER SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR INDIRECT DAMAGES, HOWEVER CAUSED AND REGARDLESS OF THEORY OF LIABILITY, INCLUDING WITHOUT LIMITATION, NEGLIGENCE AND (B) IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF KLINIKEVERYWHERE, FOR ALL CLAIMS ARISING OUT OF OR UNDER THIS AGREEMENT, EXCEED THE AMOUNT THAT THE SUBSCRIBER HAS PAID OR WILL PAY TO KLINIKEVERYWHERE FOR THE SOFTWARE UNDER THIS AGREEMENT IN THE TWELVE (12) MONTHS PRECEDING THE MOST RECENT CLAIM.

Miscellaneous

Force Majeure: KlinikEverywhere shall not liable for any losses, and/or breaches caused under this Agreement due to occurrence of any force majeure event. Force Majeure Event shall mean an event, or a series of related events, that is outside the reasonable control of the party affected (including failures of the internet or any public telecommunications network, failures of KlinikEverywhere’s hosting provider, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the law, disasters, explosions, fires, floods, riots, terrorist attacks, pandemic and its impact and wars);
Assignment: The Subscriber may not assign this Agreement or any rights herein without the prior, written consent of KlinikEverywhere.
Waiver: Failure or delay on the part of a party to exercise any right, power, privilege or remedy hereunder shall not constitute a waiver thereof. A waiver of default shall not operate as a waiver of any other default or of the same type of default on future occasions.
Notices: All notices and other communications pertaining to this Agreement shall be in writing and shall be deemed to have been given by a party hereto as set forth below and shall either be (a) personally delivered; (b) sent via postage prepaid certified mail, return receipt requested; (c) sent by nationally-recognized private express courier or (d) sent via fax provided that a confirmation copy is sent via one of the other methods described herein. Notices shall be deemed to have been given on the date of receipt if personally delivered or via fax, or two (2) days after deposit via certified mail or express courier. A party may change its address for purposes hereof by written notice to the other in accordance with the provision of this section. The addresses for the parties are as follows:
KlinikEverywhere: 121, Kabali Nagar 8th cross street Aadhanur Village, Guduvancherry TN 603202 IN.
Subscriber: As mentioned in the invoice.
Severability: If any portion of this Agreement is held invalid, the parties agree that such invalidity shall not affect the validity of the remaining portions of this Agreement, and the parties further agree to substitute for the invalid provision a valid provision that most closely approximates the economic effect and intent of the invalid provision.
Governing Law: Subject to the provision on arbitration below, this Agreement and any contractual obligation between KlinikEverywhere and the Subscriber shall be governed by the laws of India. Any dispute, claim or controversy arising out of or relating to this Agreement, including the determination of the scope or applicability of this Agreement to arbitrate, or the Subscriber’s use of the Software shall be determined by arbitration in India, before a sole arbitrator mutually appointed by the Subscriber and KlinikEverywhere. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat and venue of such arbitration shall be Bangalore. Subject to the above mentioned clause, the courts in Bangalore shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement.
KlinikEverywhere reserves the right to modify this Agreement at any time without giving the Subscriber any prior notice. The Subscriber’s use of the Software following any such modification constitutes the Subscriber’s agreement to follow and be bound by this Agreement as modified. Any additional terms and conditions, disclaimers, privacy policies and other policies applicable to general and specific areas of the Software or to particular subscription services are also considered as part of this Agreement. By agreeing to these terms, Customer also agrees to the Terms of Use, which are available at Terms of use.
                </p>
            </div>
        </TermsContainer>
    )
}
export default Terms;