
 import React, { useState, useEffect } from "react"
 import { navigate } from "gatsby"
 import { RegisterContainer } from "../components/Register/styles"
 import { Form, Popover, message, Checkbox, Select } from "antd"
 import Terms from "../components/Register/terms"
 import States from "../constants/states"
 import Countries from "../constants/countries"
 import FormItem from "antd/lib/form/FormItem"
 import { API_ROOT, TOU, SPOTCARE_LOGIN } from "gatsby-env-variables"
 
 const { Option } = Select
 const formItemLayout = {
   labelCol: {
     xs: {
       span: 24,
     },
     sm: {
       span: 8,
     },
   },
   wrapperCol: {
     xs: {
       span: 24,
     },
     sm: {
       span: 16,
     },
   },
 }
 const tailFormItemLayout = {
   wrapperCol: {
     xs: {
       span: 24,
       offset: 0,
     },
     sm: {
       span: 16,
       offset: 8,
     },
   },
 }
 const RegisterIndex = () => {
   // /=================form validation=======================================/
   const [errors, setErrors] = useState({})
   const validateName = (key, value) => {
     if (!name) {
         errors.name = "**Please  enter your full name!"
        //  errors.color = "red"
       }
     if (key === "name" && !/^[a-zA-Z ]{3,} *$/.test(value)) {
     errors.name = "**Please enter a valid name."
    //  errors.color = "red"
     } else if((name) && (/^[a-zA-Z ]{3,} *$/.test(value))) {
         errors.name = ""
     }
     return errors
   }
   const validateEmail = (key, value) => {
     if (!email) {
       errors.email = "**Please enter your email!"
      //  errors.color = "red"
     }
     if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
         errors.email = "**Please enter a valid email."
        //  errors.color = "red"
       } else if((email) && (/\S+@\S+\.\S+/.test(value))) {
         errors.email = ""
     }
     return errors
   }
   const validateGender = (key, value) => {
     
     if (!value) {
         errors.gender = "**Please select your gender!"
        //  errors.color = "red"
       } 
       else if(value) {
         errors.gender = ""
     }
     return errors
   }
   
   const validatePhone = (key, value) => {
 
   if (!phone) {
     errors.phone = "**Please enter a phone number!"
    //  errors.color = "red"
   }
    if (key === "phone" && !/^[9876][0-9]{9}$/.test(value)) {
     errors.phone = "**Please enter a valid phone number."
    //  errors.color = "red"
   }else if ((phone && /^[9876][0-9]{9}$/.test(value))){
 errors.phone=""
   }
   return errors
   }
 
  
  
   const validateLicenseno = (key, value) => {
     if (!licenseNo) {
         errors.licenseNo = "**Please enter your licenseNo!"
        //  errors.color = "red"
       }
     if (key === "licenseNo" && !/^[a-zA-Z0-9]{5,} *$/.test(value)) {
     errors.licenseNo = "**Please enter a valid licenseNo."
    //  errors.color = "red"
     } else if((licenseNo) && (/^[a-zA-Z0-9]{5,} *$/.test(value))) {
         errors.licenseNo= ""
     }
     return errors
   }
 
   const validateDoctype = (key, value) => {
     if (!value) {
         errors.selectedUserType = "** Please select your user type!"
        //  errors.color = "red"
       } else if(value) {
         errors.selectedUserType = ""
     }
     return errors
   }
 
   const validateSpeciality = (key, value) => {
     
     if (!speciality) {
         errors.speciality = "**Please select your speciality!"
        //  errors.color = "red"
       } else if(speciality) {
         errors.speciality = ""
     }
     return errors
   }
 
   const validateOrgname = (key, value) => {
     if (!orgName) {
         errors.orgName = "**Please enter the organisation name!"
        //  errors.color = "red"
       }
     if (key === "Orgname" && !/^[a-zA-Z]{5,} *$/.test(value)) {
     errors.orgName = "**Please enter a valid organisation name."
    //  errors.color = "red"
     } else if((orgName) && (/^[a-zA-Z]{5,} *$/.test(value))) {
         errors.orgName = ""
     }
     return errors
   }
 
 
   const validateOrgphone = (key, value) => {
 
     if (!orgPhone) {
       errors.orgPhone = "**Please enter your organization's phone number!"
      //  errors.color = "red"
     }
      if (key === "orgPhone" && !/^[9876][0-9]{9}$/.test(value)) {
       errors.orgPhone = "**Please enter a valid  phone number."
      //  errors.color = "red"
     }else if ((orgPhone && /^[9876][0-9]{9}$/.test(value))){
   errors.orgPhone=""
     }
     return errors
     }
     const validateAddress = (key, value) => {
       if (!address) {
           errors.address = "**Please enter an address!"
          //  errors.color = "red"
         }
       if (key === "address" && !/^[a-zA-Z]{3,} *$/.test(value)) {
       errors.address = "**Please  enter a valid address."
      //  errors.color = "red"
       } else if((address) && (/^[a-zA-Z]{3,} *$/.test(value))) {
           errors.address = ""
       }
       return errors
     }
     const validateCountry = (key, value) => {
       if (!value) {
           errors.country = "**Please select a country!"
          //  errors.color = "red"
         } else if(value) {
           errors.country = ""
       }
       return errors
     }
     const validateState = (key, value) => {
       if (!value) {
           errors.state = "**Please select a state!"
          //  errors.color = "red"
         } else if(value) {
           errors.state = ""
       }
       return errors
     }
 
     const validateCity = (key, value) => {
       if (!city) {
           errors.city= "**Please enter a city!"
          //  errors.color = "red"
         }
       if (key === "city" && !/^[a-zA-Z]{3,} *$/.test(value)) {
       errors.city = "**Please enter a valid city."
      //  errors.color = "red"
       } else if((city) && (/^[a-zA-Z]{3,} *$/.test(value))) {
           errors.city = ""
       }
       return errors
     }
     const validatePincode = (key, value) => {
 
       if (!pincode) {
         errors.pincode = "**Please enter a pincode!"
        //  errors.color = "red"
       }
        if (key === "pincode" && !/^[0-9]/.test(value)) {
         errors.pincode = "**Please enter a valid pincode."
        //  errors.color = "red"
       }else if ((pincode && /^[0-9]/.test(value))){
         errors.pincode=""
       }
       return errors
     }
     const validateTnc = (key, value) => {
 
      if (!value) {
        errors.tnc_id = "**Please accept the Terms & Conditions!"
        // errors.color = "red"
      }
      if (value){
        errors.tnc_id= ""
      }
      return errors
    }

 
   const validation = (key, value) => {
     if (!name) {
       errors.name = "**Please  enter your full name!"
      //  errors.color = "red"
     }
     if (key === "name" && !/^[a-zA-Z]{3,} *$/.test(value)) {
       errors.name = "**Please enter a valid name."
      //  errors.color = "red"
     }
 
     if (!email) {
       errors.email = "**Please enter your email!"
      //  errors.color = "red"
     }
     if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
       errors.email = "**Please enter a valid email."
      //  errors.color = "red"
     }
 
 
     if (!phone) {
       errors.phone = "**Please enter your phone number!"
      //  errors.color = "red"
     }
     if (key === "phone" && !/^[9876][0-9]{9}$/.test(value)) {
       errors.phone = "**Please enter a valid phone number."
      //  errors.color = "red"
     }
 
 
     if (!gender) {
       errors.gender = "**Please select your gender!"
      //  errors.color = "red"
     }
     if( key === "gender" && !value )
     {
         errors.gender = "**Please enter Gender"
        //  errors.color = "red"
     } else if (gender) {
        errors.gender = ""
     }

     if (!licenseNo) {
       errors.licenseNo = "**Please enter your licenseNo!"
      //  errors.color = "red"
     }
     if (key === "licenseNo" && !/^[a-zA-Z0-9]{5,} *$/.test(value)) {
       errors.licenseNo = "**Please enter a valid licenseNo."
      //  errors.color = "red"
     }
     if (!docType) {
       errors.docType = "**Please select your user type!"
      //  errors.color = "red"
     } else if (docType) {
      errors.docType = ""
     }
     if (!selectedUserType) {
      errors.selectedUserType = "**Please select your user type!"
      // errors.color = "red"
    } else if(selectedUserType) {
      errors.selectedUserType = ""
      // errors.color = "red"
    }
     // if( key === "docType" && !value )
     // {
     //     errors.docType = "**Please select your user type!"
         errors.color = "red"
     // }
     if ( !speciality )
     {
         errors.speciality = "**Please select your speciality!"
        //  errors.color = "red"
     }
     if (!orgName) {
       errors.orgName = "**Please enter the organisation name!"
      //  errors.color = "red"
     }
     if (key === "orgName " && !/^[a-zA-Z ]{5,} *$/.test(value)) {
       errors.orgName = "**Please enter a valid  organisation name."
      //  errors.color = "red"
     }
 
     if (!orgPhone) {
       errors.orgPhone = "**Please enter your organization's phone number!"
      //  errors.color = "red"
     }
     if (key === "orgPhone" && !/^[9876][0-9]{9}$/.test(value)) {
       errors.orgPhone = "**Please enter a valid phone number."
      //  errors.color = "red"
     }
     if (!address) {
       errors.address = "**Please enter an address!"
      //  errors.color = "red"
     }
     if (key === "address " && !/^[a-zA-Z ]{3,} *$/.test(value)) {
       errors.address = "**Please  enter a valid address."
      //  errors.color = "red"
     }
     if (!country) {
       errors.country = "**Please select a country!"
      //  errors.color = "red"
     } else if(country)
     {
         errors.country = ""
     }
     // if( key === "country " && !value )
     // {
     //     errors.country  = "**Please select a country"
         errors.color = "red"
     // }
 
     if (!state) {
       errors.state = "**Please select a state!"
      //  errors.color = "red"
     } else if(state)
     {
         errors.state = ""
         
     }
     if (!city) {
       errors.city = "**Please enter a city!"
      //  errors.color = "red"
     }
     if (key === "city " && !/^[a-zA-Z]{3,} *$/.test(value)) {
       errors.city = "**Please enter a valid city."
      //  errors.color = "red"
     }
     if (!pincode) {
       errors.pincode = "**Please enter a pincode!"
      //  errors.color = "red"
     }
     if (key === "pincode" && !/^[0-9]{6}/.test(value)) {
       errors.pincode = "**Please enter a valid  pincode."
      //  errors.color = "red"
     }
     if (!tnc_id) {
      errors.tnc_id = "**Please accept the Terms & Conditions!"
      // errors.color = "red"
    } else if (tnc_id) {
        errors.tnc_id = ""
    }
     return errors
   }
 
   ///-------------------------------------------------------------------------/
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [gender, setGender] = useState("")
   const [phone, setPhone] = useState("")
   const [licenseNo, setLicenseno] = useState("")
   const [docType, setDocType] = useState("")
   const [speciality, setSpeciality] = useState("")
   const [orgName, setOrgname] = useState("")
   const [orgPhone, setOrgphone] = useState("")
   const [address, setAddress] = useState("")
   const [country, setCountry] = useState("")
   const [state, setState] = useState("")
   const [city, setCity] = useState("")
   const [pincode, setPincode] = useState("")
   const [refferalCode, setRefferalcode] = useState("")
   const [selectedUserType, setSelectedUserType] = useState("")
   const [tnc_id, setTnc] = useState(false)
   const [showData, setShowData] = useState([])
 
   async function signUp() {
     const orgId = 0
     let item = {
       name: name.trim(),
       email: email.trim(),
       phone: phone,
       licenseNo: licenseNo.trim(),
       gender,
       orgName: orgName.trim(),
       orgPhone: orgPhone,
       docType,
       speciality,
       address: address.trim(),
       pincode: pincode.trim(),
       country,
       state,
       city: city.trim(),
       refferalCode: refferalCode.trim(),
       tnc_id,
       selectedUserType,
       orgId,
     }
 
     let result = await fetch(API_ROOT + "SpotCare/signup", {
       method: "POST",
       body: JSON.stringify(item),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     })
     result = await result.json()
     console.log(result)
     if (result.status === true) {
       message.success("Registration Success")
       navigate("/spotPay/", {
         state: {
           item: item,
           item2: result,
         },
       })
       signUps()
     } else {
       message.error(result.msg || result.message)
     }
   }
   function signUpp() {
     setErrors(validation())
     navigate("/Register/")
     warning()
   }
   const warning = () => {
     message.warning("Please fill all the fields")
   }
   ///================to clear up all the results in the register form================/
   const [docResult, setDocResult] = useState("")
   function signUps() {
     setName("")
     setEmail("")
     setGender("")
     setPhone("")
     setLicenseno("")
     setDocType("")
     setSelectedUserType("")
     setSpeciality("")
     setOrgname("")
     setOrgphone("")
     setAddress("")
     setCountry("")
     setState("")
     setCity("")
     setPincode("")
     setRefferalcode("")
     setShowData("")
   }
 
   ///====================for Specelist======================/
   async function getDoctor() {
     let docitem = { name }
 
     let DocTypeResult = await fetch(API_ROOT + "SpotCare/doctorType", {
       method: "POST",
       body: JSON.stringify(docitem),
       headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
       },
     })
     DocTypeResult = await DocTypeResult.json()
     console.log(DocTypeResult)
     setDocResult(DocTypeResult)
   }
   useEffect(() => {
     getDoctor()
     // eslint-disable-next-line
   }, [])
 
 
 
 
   function onTextChange(name) {
     setName(name)
     setErrors(validateName("name", name))
   }
   function onEmailChange(email) {
     setEmail(email)
    setErrors(validateEmail("email", email))
   }
   function onPhoneChange(phoneNumber) {
     setPhone(phoneNumber)
     setErrors(validatePhone("phone", phoneNumber))
   }
   function onGenderChange(gender) {
     setGender(gender)
     setErrors(validateGender("gender", gender))
   }
   function onLicensenoChange(licenseNo) {
     setLicenseno(licenseNo)
     setErrors(validateLicenseno("licenseNo", licenseNo))
   }
   function ondocTypeChange(docType) {
     setSelectedUserType(docType)
     setErrors(validateDoctype("docType", docType))
   }
   function onspecialityChange(speciality) {
     setSpeciality(speciality)
     setErrors(validateSpeciality("speciality", speciality))
   }
   function onorgNameChange(orgName) {
     setOrgname(orgName)
     setErrors(validateOrgname("orgName", orgName))
   }
   function onorgPhoneChange(orgPhone) {
     setOrgphone(orgPhone)
     setErrors(validateOrgphone("orgPhone", orgPhone))
   }
   function onaddressChange(address) {
     setAddress(address)
     setErrors(validateAddress("address", address))
   }
   function oncountryChange(country) {
     setCountry(country)
     setErrors(validateCountry("country", country))
   }
   function onstateChange(state) {
     setState(state)
     setErrors(validateState("state", state))
   }
   function oncityChange(city) {
     setCity(city)
     setErrors(validateCity("city", city))
   }
   function onpincodeChange(pincode) {
     setPincode(pincode)
     setErrors(validatePincode("pincode", pincode))
   }
   function onTncChange(tnc_id) {
     setTnc(tnc_id)
     setErrors(validateTnc("tnc", tnc_id))
   }
 
   const [form] = Form.useForm()
 
   const onFinish = values => {
     console.log("Received values of form: ", values)
   }
 
   // const prefixSelector = (
   //   <Form.Item name="prefix" noStyle>
   //     <Select
   //       style={{
   //         width: 70,
   //       }}
   //     >
   //       <Option value="Dr">Dr</Option>
   //     </Select>
   //   </Form.Item>
   // )
   return (
     <RegisterContainer>
       <Form
         {...formItemLayout}
         form={form}
         name="register"
         onFinish={onFinish}
         initialValues={{
           prefix: "Dr",
         }}
         scrollToFirstError
       ></Form>
       <div id="login_main">
         <div id="login">
           <div id="register_container">
             <div id="register_container_head">
               <div id="register_container_head_block1">
                 {/* <h1 dangerouslySetInnerHTML={{ __html: posts }} ></h1> */}
                 <h1>Register</h1>
               </div>
               <div id="register_container_head_block2">
                 <p>
                   Already a member?
                   <a
                     href={SPOTCARE_LOGIN}
                     target="_blank"
                     rel="noopener noreferrer"
                   >
                     {" "}
                     <span>Log in</span>
                   </a>
                 </p>
               </div>
             </div>
             <div id="register_inputs">
               <FormItem>
                 <div className="register_input_block1">
                   <div
                     id="register_input_block_input1"
                     // data-placeholder="Dr."
                     // class="POC"
                   >
                     {!name ? (
                       <h1 style={{ color: errors.color }}>Full Name<span class="redFont">*</span></h1>
                     ) : (
                       <h1>Full Name<span class="redFont">*</span></h1>
                     )}
 
                     <input
                       type="text"
                       placeholder="Full name"
                       value={name}
                       onChange={e => onTextChange(e.target.value)}
                       onBlur={e => validateName("name", name)}
                       required=" true"
                       // class="POC__input-field"
                       // style={{ paddingLeft: "35px" }}
                     />
                   </div>
                   <div class="redFont">{errors.name}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!email || !/\S+@\S+\.\S+/.test(email) ? (
                       <h1 style={{ color: errors.color }}>Email ID<span class="redFont">*</span></h1>
                     ) : (
                       <h1>Email ID<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="email"
                       placeholder="Email"
                       value={email}
                       onChange={e => onEmailChange(e.target.value)}
                       onBlur={e => validateEmail("email", email)}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.email}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div id="docselectorbox">
                   {/* <div className="register_input_block1">
                                 <div id="register_input_block_input1"> */}
                   {!gender ? (
                     <h1 style={{ color: errors.color }}>Gender<span class="redFont">*</span></h1>
                   ) : (
                     <h1>Gender<span class="redFont">*</span></h1>
                   )}
                   <Select
                     value={gender}
                     placeholder="Gender"
                     //className="custom-select"
                     style={{ width: "100%" }}
                     onChange={value => onGenderChange(value)}
                     // onChange={e => onGenderChange(e.target.value)}
                    //  onBlur ={e => validateGender("gender",gender)}
                     required=" true"
                   >
                     <Select.Option default hidden disabled value="">Gender</Select.Option>
                     <Select.Option value="male">Male</Select.Option>
                     <Select.Option value="female">Female</Select.Option>
                     <Select.Option value="other">Other</Select.Option>
                   </Select>
                 </div>
                 <div class="redFont">{errors.gender}</div>
                 {/* </div> */}
 
                 {/* </div> */}
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div
                     id="register_input_block_input1"
                     data-placeholder="+91"
                     class="POC"
                   >
                     {!phone ? (
                       <h1 style={{ color: errors.color }}>Contact Number<span class="redFont">*</span></h1>
                     ) : (
                       <h1>Contact Number<span class="redFont">*</span></h1>
                     )}
                     <input
                       class="POC__input-field"
                       style={{ paddingLeft: "35px" }}
                       type="number"
                       value={phone}
                       maxLength={10}
                       onChange={e => onPhoneChange(parseInt(e.target.value))}
                       onBlur={e => validatePhone(parseInt(e.target.value))}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.phone}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!licenseNo ? (
                       <h1 style={{ color: errors.color }}>Licence Number<span class="redFont">*</span></h1>
                     ) : (
                       <h1>Licence Number<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="text"
                       placeholder="Licence number"
                       value={licenseNo}
                       minLength={15}
                       maxLength={15}
                       onChange={e => onLicensenoChange(e.target.value)}
                       onBlur={e => validateLicenseno(e.target.value)}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.licenseNo}</div>
                 </div>
 
 
                
               </FormItem>
               <div id="docselectorbox">
                                 { ( !selectedUserType ) ? ( <h1 style={ { color: ( errors.color ) } }>User Type<span class="redFont">*</span></h1> ) : ( <h1>User Type<span class="redFont">*</span></h1> ) }
                                 <Select
                                 //   className="custom-select"
                           value={selectedUserType}
                           placeholder="User Type"
                           style={{ width: "100%" }}
                           onBlur={ "" }
                           onChange={(value) => ondocTypeChange(value)}
                         >
                           <Select.Option default hidden disabled value="">User Type</Select.Option>
                           <Select.Option value="Doctor">Doctor</Select.Option>
                           <Select.Option value="Chemist">Chemist</Select.Option>
                           <Select.Option value="Laboratory">Laboratory</Select.Option>
                           <Select.Option  disabled="disabled"value="Nurse">Nurse  <span className="select-label">--coming soon</span></Select.Option>
                         </Select>
                         <div class="redFont">{errors.selectedUserType}</div>          
                             </div>
 
                             { ( selectedUserType === "Doctor" ) ? ( <div id="docselectorbox">
                                 { ( !docType ) ? ( <h1 style={ { color: ( errors.color ) } }>Speciality</h1> ) : ( <h1>Speciality</h1> ) }
                                
                                      <Select
                                     //    className="custom-select"
                           placeholder="Speciality"
                         style={{ width: "100%" }}
                           onChange={(value) => setDocType(parseInt(value))}
                         >
                           {docResult && docResult.data.map(specialization => {
                              if(specialization.name != "Chemist" && specialization.name != "Laboratory") {
                                return(
                                <Select.Option value={specialization.id} key={specialization.id}>{specialization.name}</Select.Option>
                                )
                              }
                            })
                           }
                         </Select>
                             </div> ) :
                                 ( <>
                                 </> ) }
                            
 
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!orgName ? (
                       <h1 style={{ color: errors.color }}>
                         Provider Organization<span class="redFont">*</span>
                       </h1>
                     ) : (
                       <h1>Provider Organization<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="text"
                       placeholder="Provider organization"
                       value={orgName}
                       onChange={e => onorgNameChange(e.target.value)}
                       onBlur={e => validateOrgname(e.target.value)}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.orgName}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!orgPhone ? (
                       <h1 style={{ color: errors.color }}>
                         Provider Organization Phone Number<span class="redFont">*</span>
                       </h1>
                     ) : (
                       <h1>Provider Organization Phone Number<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="number"
                       placeholder="OrgPhone"
                       value={orgPhone}
                       onChange={e => onorgPhoneChange(parseInt(e.target.value))}
                       onBlur={e => validateOrgphone(parseInt(e.target.value))}
                       required=" true"
                       maxLength={10}
                       minLength={10}
                     />
                   </div>
                   <div class="redFont">{errors.orgPhone}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {address.length < 3 ? (
                       <h1 style={{ color: errors.color }}>Provider Address<span class="redFont">*</span></h1>
                     ) : (
                       <h1>Provider Address<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="text"
                       placeholder="Provider address"
                       value={address}
                       onChange={e => onaddressChange(e.target.value)}
                       onBlur={e => validateAddress(e.target.value)}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.address}</div>
                 </div>
               </FormItem>
 
               <FormItem>
               <div > 
                <div id="docselectorbox">
                        { ( !country ) ? ( <h1 style={ { color: ( errors.color ) } }>Country<span class="redFont">*</span></h1> ) : ( <h1>Country<span class="redFont">*</span></h1> ) }
                        <Select
                            className="dropdown-input"
                            placeholder="Country"
                            showSearch
                            style={{ width: "100%" }}
                            value={country}
                            onChange={value => oncountryChange(value)}
                            required=" true"
                            >
                            <Option default hidden disabled value="">Country</Option>  
                            <Option value="India">India</Option>
                        </Select>
                    </div>
                    <div class="redFont">{errors.country}</div>
                </div> 
                </FormItem>
                <FormItem>
                <div > 
                <div id="docselectorbox">
                        { ( !state ) ? ( <h1 style={ { color: ( errors.color ) } }>State<span class="redFont">*</span></h1> ) :(<h1>State<span class="redFont">*</span></h1>) }
                        <Select
                            placeholder="State"
                            value={state}
                            showSearch={country === "India"}
                            style={{ width: "100%" }}
                            onChange={value => onstateChange(value)}
                            required=" true"
                            >
                            <Option default hidden disabled value="">State</Option> 
                            {country == "India" ? States.map(state => 
                            <Option value={state}>{state}</Option>) : []}
                        </Select>
                    </div>
                    <div class="redFont">{errors.state}</div>
                </div> 
                </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!city   ? (
                       <h1 style={{ color: errors.color }}>City<span class="redFont">*</span></h1>
                     ) : (
                       <h1>City<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="text"
                       placeholder="City"
                       value={city}
                       onChange={e => oncityChange(e.target.value)}
                       onBlur={e => validateCity(e.target.value)}
                       required=" true"
                     />
                   </div>
                   <div class="redFont">{errors.city}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     {!pincode ? (
                       <h1 style={{ color: errors.color }}>PinCode<span class="redFont">*</span></h1>
                     ) : (
                       <h1>PinCode<span class="redFont">*</span></h1>
                     )}
                     <input
                       type="text"
                       placeholder="Pincode"
                       value={pincode}
                       onChange={e => onpincodeChange(e.target.value)}
                       onBlur={e => validatePincode(e.target.value)}
                       required=" true"
                       maxLength={6}
                       minLength={6}
                     />
                   </div>
                   <div class="redFont">{errors.pincode}</div>
                 </div>
               </FormItem>
               <FormItem>
                 <div className="register_input_block1">
                   <div id="register_input_block_input1">
                     <h1>Referral (if any)</h1>
                     <input
                       type="text"
                       placeholder="Referral (if any)"
                       value={refferalCode}
                       onBlur={""}
                       onChange={e => setRefferalcode(e.target.value)}
                     />
                   </div>
                 </div>
               </FormItem>
             </div>
             <FormItem>
               <div id="register_checkbox">
                 <Checkbox onChange={e => onTncChange(e.target.checked)} />
                 <p>
                   By signing up, I accept Klinik Everywhere Private Limited{" "}
                   <br></br>
                   <span>
                     <Popover content={Terms}>Terms and conditions</Popover>
                   </span>
                 </p>
               </div>
               <div class="redFont">{errors.tnc_id}</div>
               {/* <div class="redFont">{(!tnc_id) ? ("Please accept the Terms & Conditions") : "" }</div> */}
               <div id="register_button">
                 {!name ||
               
                 !email ||
                
                 !gender ||
                 !selectedUserType ||
                 !phone ||
                 phone.length < 9 ||
                
                 !licenseNo ||
                 !orgName ||
                 !orgPhone ||
                 orgPhone.length < 9 ||
                 !address ||
                 !city ||
                 !state ||
                 !pincode ||
                 !tnc_id ? (
                   <button
                     type="submit"
                     onClick={signUpp}
                     style={{ background: `gray` }}
                   >
                     SignUp
                   </button>
                 ) : (
                   <>
                     <button type="submit" onClick={signUp}>
                       SignUp
                     </button>
                   </>
                 )}
                 <button type="submit" onClick={signUps}>
                   clear
                 </button>
               </div>
             </FormItem>
           </div>
         </div>
       </div>
     </RegisterContainer>
   )
 }
 
 export default RegisterIndex